<template>
  <div v-if="data && data.length" class="products-success-cases">
    <public-title :data-info="titleInfo" />
    <div class="slogan-box container">
      <!-- <ul class="slogan-list">
        <li
          v-for="(item, index) in data"
          :key="index"
          :style="`background: url(${item.image}) center center / cover no-repeat`"
          class="items"
        >
          <div class="text-bg">
            <div class="text-box">
              <h5 class="title">{{ item.title }}</h5>
              <p class="desc">{{ item.description }}</p>
            </div>
          </div>
        </li>
      </ul> -->
      <swiper ref="sloganSwiper" class="slogan-list" :options="sloganSwiperOption">
        <swiper-slide v-for="(item, index) in data" :key="index" class="slogan-item">
          <div class="items-img" :style="`background: url(${item.image}) center center / cover no-repeat`">
          </div>
          <div class="text-bg">
            <div class="text-box">
              <h5 class="title">{{ item.title }}</h5>
              <p class="desc">{{ item.description }}</p>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div slot="button-prev" class="success-button-prev">
        <span class="iconfont icon-a-zuojiantou1x icon"></span>
      </div>
      <div slot="button-next" class="success-button-next">
        <span class="iconfont icon-a-youjiantou1x icon"></span>
      </div>
    </div>
  </div>
</template>

<script>
import PublicTitle from '@/components/public/PublicTitle.vue'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.min.css'
export default {
  components: {
    PublicTitle,
    swiperSlide,
    swiper
  },
  props: {
    data: {
      type: [Array, Object],
      default: () => []
    }
  },
  data() {
    return {
      titleInfo: {
        title: this.LP.lang_success_cases
      },
      sloganSwiperOption: {
        autoplay: false, // 自动切换
        loop: false, // 无限循环
        slidesPerView: '3', // 每屏显示个数
        spaceBetween: 16,
        centerInsufficientSlides: true, // 居中
        navigation: { // 前进后退按钮
          nextEl: '.success-button-next',
          prevEl: '.success-button-prev',
          disabledClass: 'my-button-disabled'
        },
        breakpoints: {
          1024: {
            slidesPerView: '2'
          },
          768: {
            slidesPerView: '1'
          }
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common';
.products-success-cases {
  .slogan-box {
    position: relative;
    .slogan-list {
      .slogan-item {
        max-width: 416px;
        position: relative;
        .items-img {
          height: 320px;
          background: #D8D8D8;
          border-radius: 5px;
          // cursor: pointer;
        }
        .text-bg {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 66px;
            background: linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.77) 100%);
            overflow: hidden;
            padding: 24px;
            border-radius: 5px;
            .transition(0.4s);
            .text-box {
              .title {
                font-size: 18px;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 18px;
                padding-bottom: 24px;
              }
              .desc {
                font-size: 14px;
                color: #F4F4F5;
                line-height: 19px;
              }
            }
        }
        &:hover {
            .text-bg {
              height: 100%;
              .text-box {
                position: absolute;
                bottom: 24px;
                .title {
                  color: @theme;
                  padding-bottom: 10px;
                }
              }
            }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
      .success-button-prev,
      .success-button-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        width: 48px;
        height: 48px;
        text-align: center;
        border-radius: 50%;
        .icon {
          font-size: 32px;
          color: #fff;
          line-height: 48px;
        }
      }
      .success-button-prev {
        left: 0;
      }
      .success-button-next {
        right: 0;
      }
      .my-button-disabled{
        opacity:0.2;
      }
  }
  @media screen and (max-width: 1024px) {
    .slogan-box {
      .slogan-list {
        .slogan-item {
          .items-img {
            height: 240px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
     /deep/ .public-title {
      .text-box {
        padding: 40px 0 15px;
      }
    }
    .slogan-box {
      .slogan-list {
        display: block;
        .slogan-item {
          .items-img {
            margin-right: 0;
            margin-bottom: 10px;
            .text-bg {
              height: 50px;
              padding: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
